import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { interval } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  rotationTabIndex1 = 0;
  rotationTabIndex2 = 4;
  rotationTabIndex3 = 8;

  constructor(private title: Title) { }

  ngOnInit() {  
    this.title.setTitle("LIUMEX");
    var rotationInterval = interval(3000);
    rotationInterval.subscribe( x => this.rotateIcons());
  }

  rotateIcons() {
    var tabCount = 18;
    this.rotationTabIndex1 = (this.rotationTabIndex1 + 1) % tabCount;
    this.rotationTabIndex2 = (this.rotationTabIndex2 + 1) % tabCount;
    this.rotationTabIndex3 = (this.rotationTabIndex3 + 1) % tabCount;
  }

}
