export const environment = {
  firebaseConfig: {
    apiKey: "AIzaSyBGXCPg4b7Ky-djslH6lWM8h4dp3hfmj5o",
    authDomain: "liumex.firebaseapp.com",
    databaseURL: "https://liumex-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "liumex",
    storageBucket: "liumex.appspot.com",
    messagingSenderId: "472208918985",
    appId: "1:472208918985:web:6b6681fbd2b1cf2b514018"
  },
  notificationDuration: 3500,
  assetDomain: "https://assets.liumex.com/",
  authUrl: "https://account.liumex.com/auth?redirectTo="
};