<mat-card>
    <div class="page-header">
        <img ngSrc="https://assets.liumex.com/resources/liumex/content/pc-bank.png" alt="" fill priority>
        <div>Join Our Team</div>
    </div>
    <mat-card-content>
        <p>We're always on the lookout for passionate individuals to work in the Esports industry. At LIUMEX we're dedicated to fostering a culture of inclusivity and diversity. 
            We believe that diverse perspectives and backgrounds enrich our team and strengthen our abilities. We welcome individuals from all walks of life, regardless 
            of ethnicity, gender, sexual orientation, religion, disability or any other characteristic. Our goal is to create an environment where everyone feels valued, 
            respected and empowered to contribute their unique talents and experiences.</p>
        <p>While we may not currently have any open full-time positions or temporary job postings, we welcome talented professionals who are interested in collaborating with 
            us as freelancers.</p>
        <p>Please note that all applicants must be 18 years of age or older to be considered for any opportunities.</p>
        <mat-card-title>Openings</mat-card-title>
        <ul>
            <li>
                <span>Tournament Admin (Freelance)</span>
                <p>Responsibilities:</p>
                <ul>
                    <li>Organizing and coordinating Esports tournaments</li>
                    <li>Managing tournament logistics such as scheduling and equipment setup</li>
                    <li>Communicating with participants, teams, and stakeholders</li>
                    <li>Collaborating with the production team</li>
                    <li>Overseeing tournament operations</li>
                    <li>Enforcing the rules during matches</li>
                    <li>Handling any technical issues or disputes</li>
                    <li>Collecting and analyzing feedback</li>
                </ul>
                <p>Requirements:</p>
                <ul>
                    <li>Previous experience in organizing and managing Esports tournaments</li>
                    <li>Strong organizational skills and attention to detail</li>
                    <li>Excellent communication and interpersonal skill</li>
                    <li>Ability to multitask and work well under pressure in a fast-paced environment</li>
                    <li>Knowledge of Esports tournament formats, rulesets and regulations</li>
                    <li>Proficiency in using tournament management software</li>
                    <li>Flexibility to work evenings and weekends</li>
                </ul>
            </li>
            <mat-divider></mat-divider>
            <li>
                <span>Esports Referee (Freelance)</span>
                <p>Responsibilities:</p>
                <ul>
                    <li>Ensuring fairness and compliance with the rules</li>
                    <li>Enforcing tournament rules and regulations</li>
                    <li>Making real-time decisions on match-related issues</li>
                    <li>Communicating with players, coaches and tournament operations</li>
                    <li>Providing guidance to players on rule interpretations</li>
                    <li>Collaborating with other referees and tournament officials</li>
                    <li>Remaining impartial and objective</li>
                </ul>
                <p>Requirements:</p>
                <ul>
                    <li>Deep understanding of the games being played</li>
                    <li>Excellent decision-making and problem-solving skills</li>
                    <li>Strong communication and interpersonal skills</li>
                    <li>Knowledge of Esports tournament formats, rulesets and regulations</li>
                    <li>Flexibility to work evenings and weekends</li>
                    <li>Flexibility to travel</li>
                    <li>Ability to remain calm and focused under pressure</li>
                </ul>
            </li>
            <mat-divider></mat-divider>
            <li>
                <span>Team Handler (Freelance)</span>                
                <p>Responsibilities:</p>
                <ul>
                    <li>Providing support and assistance to teams</li>
                    <li>Coordinating team logistics such as travel arrangements and accommodation</li>
                    <li>Ensuring teams have access to necessary resources and equipment</li>
                    <li>Acting as a contact between teams and tournament operations</li>
                    <li>Handling administrative tasks such as managing schedules, budgets, and paperwork</li>
                </ul>
                <p>Requirements:</p>
                <ul>
                    <li>Strong organizational and time-management skills</li>
                    <li>Excellent communication and interpersonal skills</li>
                    <li>Flexibility to travel and work irregular hour</li>
                    <li>Ability to remain calm and composed under pressure</li>
                </ul>
            </li>
        </ul>
        <p>Sounds like you? Reach out to us at <a href="mailto:career@liumex.com">career&#64;liumex.com</a></p>
    </mat-card-content>
</mat-card>