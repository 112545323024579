<div class="footer-container">
    <div class="cookie-container">
        <mat-icon aria-label="Cookies" class="cookie-consent-center" (click)="openCookieConsentCenter()" matTooltip="Cookies">cookie</mat-icon>
    </div>
    <div class="footer-content">
        <a routerLink="imprint">Imprint</a>
        <a routerLink="privacy">Privacy</a>
        <a routerLink="terms">Terms of Service</a>
        <br>
        <a target="_blank" href="https://support.liumex.com/">Support</a>
        <p>© {{ currentYear }} LIUMEX</p>
    </div>
</div>