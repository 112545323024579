import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LiumexAuthGuard } from 'src/services/liumex-auth.guard';
import { HomeComponent } from './home/home.component';
import { ImprintComponent } from './imprint/imprint.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TosComponent } from './tos/tos.component';
import { ConsultingComponent } from './consulting/consulting.component';
import { TourOpsComponent } from './tour-ops/tour-ops.component';
import { CareersComponent } from './careers/careers.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  // {
  //   path: 'tournament-ops',
  //   component: TourOpsComponent,
  //   canActivate: [LiumexAuthGuard]
  // },
  // {
  //   path: 'consulting',
  //   component: ConsultingComponent,
  //   canActivate: [LiumexAuthGuard]
  // },
  {
    path: 'careers',
    component: CareersComponent,
    canActivate: [LiumexAuthGuard]
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [LiumexAuthGuard]
  },
  {
    path: 'imprint',
    component: ImprintComponent,
    canActivate: [LiumexAuthGuard]
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    canActivate: [LiumexAuthGuard]
  },
  {
    path: 'terms',
    component: TosComponent,
    canActivate: [LiumexAuthGuard]
  },
  {
    path: '',
    component: HomeComponent, 
    canActivate: [LiumexAuthGuard],
  },
  { 
    path: '**', 
    redirectTo: '',
  }
];

export const authNeeded = [
  
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
