import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  constructor(private title: Title) { }

  ngOnInit() {  
    this.title.setTitle("Contact - LIUMEX");
  }
}
