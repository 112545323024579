import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent {
  
  constructor(private title: Title) { }

  ngOnInit() {  
    this.title.setTitle("Careers - LIUMEX");
  }
}
