<mat-card>
    <div class="page-header">
        <img ngSrc="https://assets.liumex.com/resources/liumex/content/nes-console.png" alt="" fill priority>
        <div>Get In Touch</div>
    </div>
    <mat-card-content>
        <mat-card-title>LIUMEX</mat-card-title>
        <p>You want to book our services for your next project or you got a brilliant idea you want to discuss? Than contact us at:</p>
        <p><a href="mailto:contact@liumex.com">contact&#64;liumex.com</a></p>
        <p>If you have questions or comments about your privacy rights please contact us at:</p>
        <p><a href="mailto:privacy@liumex.com">privacy&#64;liumex.com</a></p>
        <mat-divider></mat-divider>
        <mat-card-title>EsportsMS</mat-card-title>
        <p>Looking to streamline your competitions with our EsportsMS? Our sales team is ready to assist you with your subscription request!</p>
        <p><a href="mailto:sales@esportsms.com">sales&#64;esportsms.com</a></p>
    </mat-card-content>
</mat-card>