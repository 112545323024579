import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent {

  constructor(private title: Title) { }

  ngOnInit() {  
    this.title.setTitle("Imprint - LIUMEX");
  }
}
