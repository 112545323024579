<mat-card>
  <div class="page-header">
    <img ngSrc="https://assets.liumex.com/resources/liumex/content/retro-gaming.png" alt="" fill priority>
    <div>Limitless Ultimate Experience</div>
  </div>
  <mat-card-content>
    <p>Unlock the full potential of your competition and redefine the landscape of Esports. Our commitment to excellence, innovation 
      and customer satisfaction makes us to your perfect partner. "Limitless Ultimate Experience" is more than a slogan, it's the 
      philosophy that drives everything we do. We want to push the boundaries of Esports with our experience and skills to create 
      the future.</p>
    <mat-tab-group class="icon-rotation" animationDuration="0ms" [(selectedIndex)]="rotationTabIndex1">
      <mat-tab>
        <mat-icon>headset_mic</mat-icon>
        <mat-icon>keyboard</mat-icon>
        <mat-icon>sports_esports</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>forum</mat-icon>
        <mat-icon>work</mat-icon>
        <mat-icon>group</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>computer</mat-icon>
        <mat-icon>code</mat-icon>
        <mat-icon>terminal</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>headset_mic</mat-icon>
        <mat-icon>sports_esports</mat-icon>
        <mat-icon>keyboard</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>forum</mat-icon>
        <mat-icon>group</mat-icon>
        <mat-icon>work</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>computer</mat-icon>
        <mat-icon>terminal</mat-icon>
        <mat-icon>code</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>keyboard</mat-icon>
        <mat-icon>headset_mic</mat-icon>
        <mat-icon>sports_esports</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>work</mat-icon>
        <mat-icon>forum</mat-icon>
        <mat-icon>group</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>code</mat-icon>
        <mat-icon>computer</mat-icon>
        <mat-icon>terminal</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>sports_esports</mat-icon>
        <mat-icon>headset_mic</mat-icon>
        <mat-icon>keyboard</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>group</mat-icon>
        <mat-icon>forum</mat-icon>
        <mat-icon>work</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>terminal</mat-icon>
        <mat-icon>computer</mat-icon>
        <mat-icon>code</mat-icon>
      </mat-tab>
      <mat-tab>        
        <mat-icon>keyboard</mat-icon>
        <mat-icon>sports_esports</mat-icon>
        <mat-icon>headset_mic</mat-icon>
      </mat-tab>
      <mat-tab>        
        <mat-icon>work</mat-icon>
        <mat-icon>group</mat-icon>
        <mat-icon>forum</mat-icon>
      </mat-tab>
      <mat-tab>        
        <mat-icon>code</mat-icon>
        <mat-icon>terminal</mat-icon>
        <mat-icon>computer</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>sports_esports</mat-icon>
        <mat-icon>keyboard</mat-icon>
        <mat-icon>headset_mic</mat-icon>      
      </mat-tab>
      <mat-tab>
        <mat-icon>group</mat-icon>
        <mat-icon>work</mat-icon>
        <mat-icon>forum</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>terminal</mat-icon>
        <mat-icon>code</mat-icon>
        <mat-icon>computer</mat-icon>
      </mat-tab>
    </mat-tab-group>
    <mat-card-title>Our Services:</mat-card-title>
    <ul>
      <li>
        <span>Tournament Operations</span>
        <p>We are specializes in the organization and execution of Esports tournaments. Our team ensures 
          a fair and great competition for participants and spectators. From the intricate details of online tournaments 
          to the scale of offline spectacles, we customize each event to the unique requirements.</p>
      </li>
      <li>
        <span>Esports Consulting</span>
        <p>In the dynamic landscape of Esports strategic insights are essential. We offers a wide range of industry knowledge. 
          Our team has a deep understanding of market trends and game dynamics. Work with us to navigate the 
          complexity of the industry and position yourself at the top of Esports.</p>
      </li>
    </ul>
    <mat-tab-group class="icon-rotation" animationDuration="0ms" [(selectedIndex)]="rotationTabIndex2">
      <mat-tab>
        <mat-icon>headset_mic</mat-icon>
        <mat-icon>keyboard</mat-icon>
        <mat-icon>sports_esports</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>forum</mat-icon>
        <mat-icon>work</mat-icon>
        <mat-icon>group</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>computer</mat-icon>
        <mat-icon>code</mat-icon>
        <mat-icon>terminal</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>headset_mic</mat-icon>
        <mat-icon>sports_esports</mat-icon>
        <mat-icon>keyboard</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>forum</mat-icon>
        <mat-icon>group</mat-icon>
        <mat-icon>work</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>computer</mat-icon>
        <mat-icon>terminal</mat-icon>
        <mat-icon>code</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>keyboard</mat-icon>
        <mat-icon>headset_mic</mat-icon>
        <mat-icon>sports_esports</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>work</mat-icon>
        <mat-icon>forum</mat-icon>
        <mat-icon>group</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>code</mat-icon>
        <mat-icon>computer</mat-icon>
        <mat-icon>terminal</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>sports_esports</mat-icon>
        <mat-icon>headset_mic</mat-icon>
        <mat-icon>keyboard</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>group</mat-icon>
        <mat-icon>forum</mat-icon>
        <mat-icon>work</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>terminal</mat-icon>
        <mat-icon>computer</mat-icon>
        <mat-icon>code</mat-icon>
      </mat-tab>
      <mat-tab>        
        <mat-icon>keyboard</mat-icon>
        <mat-icon>sports_esports</mat-icon>
        <mat-icon>headset_mic</mat-icon>
      </mat-tab>
      <mat-tab>        
        <mat-icon>work</mat-icon>
        <mat-icon>group</mat-icon>
        <mat-icon>forum</mat-icon>
      </mat-tab>
      <mat-tab>        
        <mat-icon>code</mat-icon>
        <mat-icon>terminal</mat-icon>
        <mat-icon>computer</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>sports_esports</mat-icon>
        <mat-icon>keyboard</mat-icon>
        <mat-icon>headset_mic</mat-icon>      
      </mat-tab>
      <mat-tab>
        <mat-icon>group</mat-icon>
        <mat-icon>work</mat-icon>
        <mat-icon>forum</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>terminal</mat-icon>
        <mat-icon>code</mat-icon>
        <mat-icon>computer</mat-icon>
      </mat-tab>
    </mat-tab-group>
    <mat-card-title>What Sets LIUMEX Apart?</mat-card-title>
    <ul>
      <li>We have developed tons of experience through our commitment to excellence</li>
      <li>We are adaptable and learn from our mistakes</li>
      <li>We have successfully mastered online and offline tournaments from B to S-tier</li>
      <li>We organize all competitions based on S-Tier standards</li>
      <li>We have a large network of talented people in the esports scene</li>
    </ul>
    <mat-tab-group class="icon-rotation" animationDuration="0ms" [(selectedIndex)]="rotationTabIndex3">
      <mat-tab>
        <mat-icon>headset_mic</mat-icon>
        <mat-icon>keyboard</mat-icon>
        <mat-icon>sports_esports</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>forum</mat-icon>
        <mat-icon>work</mat-icon>
        <mat-icon>group</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>computer</mat-icon>
        <mat-icon>code</mat-icon>
        <mat-icon>terminal</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>headset_mic</mat-icon>
        <mat-icon>sports_esports</mat-icon>
        <mat-icon>keyboard</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>forum</mat-icon>
        <mat-icon>group</mat-icon>
        <mat-icon>work</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>computer</mat-icon>
        <mat-icon>terminal</mat-icon>
        <mat-icon>code</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>keyboard</mat-icon>
        <mat-icon>headset_mic</mat-icon>
        <mat-icon>sports_esports</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>work</mat-icon>
        <mat-icon>forum</mat-icon>
        <mat-icon>group</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>code</mat-icon>
        <mat-icon>computer</mat-icon>
        <mat-icon>terminal</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>sports_esports</mat-icon>
        <mat-icon>headset_mic</mat-icon>
        <mat-icon>keyboard</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>group</mat-icon>
        <mat-icon>forum</mat-icon>
        <mat-icon>work</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>terminal</mat-icon>
        <mat-icon>computer</mat-icon>
        <mat-icon>code</mat-icon>
      </mat-tab>
      <mat-tab>        
        <mat-icon>keyboard</mat-icon>
        <mat-icon>sports_esports</mat-icon>
        <mat-icon>headset_mic</mat-icon>
      </mat-tab>
      <mat-tab>        
        <mat-icon>work</mat-icon>
        <mat-icon>group</mat-icon>
        <mat-icon>forum</mat-icon>
      </mat-tab>
      <mat-tab>        
        <mat-icon>code</mat-icon>
        <mat-icon>terminal</mat-icon>
        <mat-icon>computer</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>sports_esports</mat-icon>
        <mat-icon>keyboard</mat-icon>
        <mat-icon>headset_mic</mat-icon>      
      </mat-tab>
      <mat-tab>
        <mat-icon>group</mat-icon>
        <mat-icon>work</mat-icon>
        <mat-icon>forum</mat-icon>
      </mat-tab>
      <mat-tab>
        <mat-icon>terminal</mat-icon>
        <mat-icon>code</mat-icon>
        <mat-icon>computer</mat-icon>
      </mat-tab>
    </mat-tab-group>
    <mat-card-title>Our Applications:</mat-card-title>
    <p>Over time we've developed several applications that have not only helped us in advancing projects but have also optimized 
      processes within our own operations. These applications often cater to specific use cases, born out of the absence of 
      satisfactory solutions in the market. Fueled by our dissatisfaction with existing tools, we took matters into our own hands 
      and developed custom solutions tailored to our needs. However, instead of keeping these tools restricted to internal use, 
      we've made the decision to share them with the world. Our suite of applications spans across various fields, not limited 
      to Esports. Many of our functions are available for free. However, to drive further developments and unlock additional 
      features, we offer premium variants with enhanced functionalities.</p>
  </mat-card-content>
</mat-card>